import React from 'react';

class Indicator extends React.Component {
  
    render() {
      if(this.props.item === "TurnedOff")
      {
        return (<div className="led-red"><span className="tooltiptext">Turned Off</span></div>)
      }
      else if(this.props.item === "Available")
      {
        return (<div className="led-green"><span className="tooltiptext">Available</span></div>)
      }
      else if(this.props.item === "InUse")
      {
        return (<div className="led-blue"><span className="tooltiptext">In Use</span></div>)
      }
      else{
          return (<div className="led-yellow"><span className="tooltiptext">{this.props.item}</span></div>)
      }
    }
  }

  
export default Indicator;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Indicator from './Indicator';

function GenerateRdpFile(hostname) {
  return (`
screen mode id:i:2
use multimon:i:0
desktopwidth:i:1920
desktopheight:i:1080
session bpp:i:32
winposstr:s:0,1,454,355,1016,654
compression:i:1
keyboardhook:i:2
audiocapturemode:i:0
videoplaybackmode:i:1
connection type:i:7
networkautodetect:i:1
bandwidthautodetect:i:1
displayconnectionbar:i:1
enableworkspacereconnect:i:0
disable wallpaper:i:0
allow font smoothing:i:0
allow desktop composition:i:0
disable full window drag:i:1
disable menu anims:i:1
disable themes:i:0
disable cursor setting:i:0
bitmapcachepersistenable:i:1
full address:s:${hostname}
audiomode:i:0
redirectprinters:i:1
redirectcomports:i:0
redirectsmartcards:i:1
redirectclipboard:i:1
redirectposdevices:i:0
autoreconnection enabled:i:1
authentication level:i:2
prompt for credentials:i:0
negotiate security layer:i:1
remoteapplicationmode:i:0
alternate shell:s:
shell working directory:s:
gatewayhostname:s:
gatewayusagemethod:i:4
gatewaycredentialssource:i:4
gatewayprofileusagemethod:i:0
promptcredentialonce:i:0
gatewaybrokeringtype:i:0
use redirection server name:i:0
rdgiskdcproxy:i:0
kdcproxyname:s:
`)
}



const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'instanceId', label: 'Instance Id', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 }

  ];
  
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: '80%',
    },
  });

function Main(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = event => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const stopServer = async (instanceId) => {
      var result = window.confirm("Are you sure?")
      if(result === false) { return }
      await fetch('https://piwrho5f25.execute-api.ap-southeast-2.amazonaws.com/Prod/servers/stop/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': props.apiToken
        },
        body: JSON.stringify({
          InstanceId: instanceId,
        })
      })
      window.alert("Server is stopping");
      props.refreshState()
    }

    const startServer = async (instanceId) => {
	  console.log(props)
      var result = window.confirm("Are you sure?")
      if(result === false) { return }
      let a = await fetch(
        'https://piwrho5f25.execute-api.ap-southeast-2.amazonaws.com/Prod/servers/start/',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': props.apiToken
          },
          body: JSON.stringify({
            InstanceId: instanceId
          })
        }
      )
      if(a.status === 200)
      {
        window.alert("Server is starting");
      }
      else if(a.status === 401)
      {
        window.alert("Unauthorized, You may not start a server outside of its allowed start hours");
      }
      else
      {
        window.alert("Something went wrong. please contact your Administrator for assistance")
      }
      props.refreshState()
    }

    const downloadTxtFile = (lb, port, name) => {
      const element = document.createElement("a");
      const file = new Blob([GenerateRdpFile(lb + ":" + port)], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = name + ".rdp";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  
    const openAlb = (lb, name) => {
      var alb = "https://" + lb + "/" + name + "/"
      window.open(alb)
    }
  
      
    return (

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.servers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                var loginButton;
                if(row.port === "")
                {
                  loginButton = 
                    (<TableCell key="login" align='center'>
                      <Button variant="outlined" color="primary" onClick={() => openAlb(row.lb, row.name)}>Log In</Button>
                    </TableCell>)
                }
                else
                {
                  loginButton = 
                    (<TableCell key="login" align='center'>
                      <Button variant="outlined" color="primary" onClick={() => downloadTxtFile(row.lb, row.port, row.name)}>Log In</Button>
                    </TableCell>)
                }
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key="indicator" align='left'>
                      <Indicator item={row.status} key={row.status}></Indicator>
                    </TableCell>
                    {loginButton}
                    <TableCell key="start" align='right'>
                      <Button variant="contained" color="primary" onClick={() => startServer(row.instanceId)}>Start</Button>
                    </TableCell>
                    <TableCell key="stop" align='left'>
                      <Button variant="contained" color="secondary" onClick={() => stopServer(row.instanceId)}>Stop</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.servers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    );
}

export default Main;
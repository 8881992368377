import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Login(props) {
  const [open, setOpen] = React.useState(props.openLogin);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleLogout = async () => {
	props.handleLogOut();
  }

  const handleLogin = async () => {
    let a = await fetch(
        'https://piwrho5f25.execute-api.ap-southeast-2.amazonaws.com/Prod/login/',
        {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: username,
            password: password
        })
        }
    )
    if(a.status === 200)
    {
        var respBody = (await a.json())
        document.cookie = "auth="+respBody.token+";expires="+respBody.expiry
        props.handleUpdateApiToken(respBody.token)
        handleClose()
    }
    else
    {
        window.alert("Incorrect login details");
    }
  }

	if(props.openLogin === false)
	{
		return (<div>
	<Button variant="outlined" color="primary" onClick={handleLogout}>
			Log Out
		  </Button>
		</div>)
	}
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Log In
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Log In"}</DialogTitle>
        <DialogContent>
            <Grid container spacing={4} alignItems="flex-end">
                <Grid item>
                    <Face />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                    <TextField id="username" label="Username" onChange={(e) => setUsername(e.target.value)} type="email" fullWidth autoFocus required />
                </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="flex-end">
                <Grid item>
                    <Fingerprint />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                    <TextField id="password" label="Password" onChange={(e) => setPassword(e.target.value)}  type="password" fullWidth required />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary">
            Log In
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
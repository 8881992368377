import React from 'react';
import './App.css';
import Menu from './Menu';
import Main from './Main';
import Login from './Login';

var hidden = "hidden"
var visibilityChange = "visibilitychange"
class Header extends React.Component {
  constructor(props) {
    super(props);
    
    let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    this.state = {
      list: null,
      showList: [],
      selectedClient: "",
      interval: "",
      apiToken: cookieValue
    };
    


    this.handleClick = this.handleClick.bind(this)
    this.handler = this.handler.bind(this)
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
    this.updateApiToken = this.updateApiToken.bind(this)
	this.logOut = this.logOut.bind(this)
  }

    
  handler = async () => {
	if(this.state.apiToken === "")
	{
		return
	}
    await fetch(
      'https://piwrho5f25.execute-api.ap-southeast-2.amazonaws.com/Prod/servers/',
      {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.state.apiToken
        }
      }
  ).then(p => p.json()).then(p => p.Projects).then(p => this.setState ({ list: p }));
    this.updateSelectedClient()
  }

  updateSelectedClient = async () => {
    if (this.state.selectedClient === "")
    {
      if (this.state.list !== undefined)
      {
        this.setState({
          showList: this.state.list[0].ProjectServers
        });
      }
    }
    else
    {
      var thisList = this.state.list.filter( (num) => num.ProjectName === this.state.selectedClient )
      this.setState({
        showList: thisList[0].ProjectServers
      })
    }
  }

  handleVisibilityChange() {
    if(document[hidden]) {
      try{
        clearInterval(this.state.interval)
      }
      catch {}
    }
    else
    {
      this.handler()
      var int = setInterval(this.handler, 1200000)
      this.setState({
        interval: int
      })
    }
  }
  
  updateApiToken = async (token) => {
    this.setState({ apiToken: token })
    this.handler()
  }
  
  logOut = async () => {
	this.setState({ 
		apiToken: "",
		showList: []
	})
	document.cookie = "auth=logout;expires=Fri, 27 Jul 2001 02:47:11 UTC;"
	console.log("LOGGING OUT")
  }


  componentDidMount() {
    this.handleVisibilityChange()
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    
  }

  handleClick = async (text) => {
    await this.setState({ selectedClient: text })
    this.updateSelectedClient()
  }


  render() {
  return (
      <div>
          <Menu servers={this.state.list} handleClick={this.handleClick} />
          <Main servers={this.state.showList} refreshState={this.handler} apiToken={this.state.apiToken}/>
          <Login handleUpdateApiToken={this.updateApiToken} handleLogOut={this.logOut} openLogin={this.state.apiToken === ""} />
      </div>
  );
  }
}

export default Header;